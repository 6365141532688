<template>
    <div class="notice">
        <div class="title" v-if="showTitle">公告</div>
        <div class="card">
            <div class="item1 flex">
                <div class="name">公告内容：</div>
                <div class="itembox" :class="query_data.type == item.id ? 'a_box' : ''" @click="change(item, 'type')"
                    v-for="item, index in content_list" :key="index">{{ item.name }}</div>
            </div>
            <div class="item1 item2 flex">
                <div class="name">项目类型：</div>
                <div class="itembox" @click="change(item, 'jzOrZf')" :class="query_data.jzOrZf == item.id ? 'a_box' : ''"
                    v-for="item in type" :key="item.id">{{ item.name }}</div>
            </div>
            <div class="item1 flex">
                <div class="name">项目区域：</div>
                <div class="itembox" @click="change(item, 'xiaqu')" :class="query_data.xiaqucode == item.id ? 'a_box' : ''"
                    v-for="(item, index) in area_arr" :key="index">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { area } from '@/utils/area.js'
export default {
    name: 'notice',
    components: {},
    props: {
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            area_arr: [], // 项目区域
            content_list: [
                {
                    name: '招标公告',
                    id: 'zbgg'
                }, {
                    name: '变更公告',
                    id: 'bggg'
                }, {
                    name: '补遗答疑',
                    id: 'bydy'
                }, {
                    name: '中标候选人公示',
                    id: 'hxrgs'
                }, {
                    name: '中标公告',
                    id: 'zhongbiaogg'
                }, {
                    name: '异常公告',
                    id: 'ycgg'
                },
                {
                    name: '招标计划公告',
                    id: 'jhgg'
                }
            ],
            type: [
                {
                    name: '全部',
                    id: 0
                },
                {
                    name: '建设工程',
                    id: 1
                },
                {
                    name: '政府采购',
                    id: 2
                },
            ],
            query_data: {
                type: 'zbgg',
                jzOrZf: 0,
                xiaqucode: 13,

            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.area_arr = [...area];
    },
    mounted() {


    },
    // 组件方法
    methods: {
        // 项目类型
        change(item, type) {
            if (type == 'type') this.query_data.type = item.id;
            else if (type == 'jzOrZf') this.query_data.jzOrZf = item.id;
            else if (type == 'xiaqu') this.query_data.xiaqucode = item.id;
            this.emit_change();
        },
        emit_change() {
            let obj = { ...this.query_data };
            obj.jzOrZf == 0 ? obj.jzOrZf = '' : obj.jzOrZf;
            obj.xiaqucode == 13 ? obj.xiaqucode = '' : obj.xiaqucode;
            this.$emit('change', obj);
        },

    }
}
</script>

<style scoped lang="scss">
.notice {
    .title {
        font-size: 24px;
        font-weight: 700;
        padding: 40px 0 40px 0;
        text-align: center;
        position: relative;

        span {
            position: absolute;
            color: rgba(0, 0, 0, 0.03);
            font-size: 36px;
            left: 0;
            top: 70px;
            right: 0;

        }
    }

    .card {
        box-sizing: border-box;
        padding: 30px;
        margin: auto;
        width: 1200px;
        // height: 228px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(24, 29, 41, 0.08);
        border-radius: 10px;

        .item1 {
            // padding: 15px 0px;
            // padding: 5px 10px;
            font-size: 18px;
            justify-content: flex-start;
            cursor: pointer;


            .name {
                margin-right: 10px;
                font-size: 20px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: 700;
                color: #323347;
            }

            .itembox {
                color: #ADABB3;
                margin-right: 10px;
                padding: 5px;

                &:hover {
                    color: #006EFF;
                }

            }

            .a_box {
                padding: 5px 10px;
                background: linear-gradient(315deg, #2D97FF 0%, #31AEFF 100%);
                box-shadow: 0px 4px 10px 0px rgba(1, 90, 236, 0.15);
                border-radius: 2px;
                color: #fff;
            }
        }

        .item2 {
            padding: 30px 0;
        }
    }
}
</style>
