<template>
  <div>
    <div class="top">
      <div class="w flex">
        <div class="left flex" @click="check(lists[0], 0)">
          <img src="../assets/homeimg/logo.png" alt="">
          <div class="logo">
            <div>招之即来</div>
            <!-- <div class="logot" style="font-size: 10px;">Octopus tenders</div> -->
          </div>

        </div>
        <div class="right flex">
          <div class="tabs flex" :class="active_index == item.router_name ? 'tabs_i' : 'tabs'"
            v-for="(item, index) in lists" @click="check(item, index)" :key="index">
            {{ item.name }} <span></span>
          </div>
          <!-- <div class="btn flex">
                    <div class="login">登录</div>
                    <div class="regit">注册</div>
                </div> -->
        </div>
      </div>

    </div>

    <div style="height: 90px;"></div>
    <div class="swiper" v-if="swiper_list.length">
      <el-carousel :interval="3000" height="470px" arrow="always">
        <el-carousel-item v-for="item in swiper_list" :key="item">
          <img :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import { thing_list, get_img_url } from '@/api/home'
export default {
  name: 'navBar',
  props: {},
  data() {
    return {
      active_index: 'home',
      lists: [
        {
          name: '首页',
          path: '/',
          router_name: 'home'
        },
        {
          name: '交易信息',
          path: '/transaction',
          router_name: 'transaction'
        },
        {
          name: '新闻公告',
          path: '/news',
          router_name: 'news'
        },
        {
          name: '政策法规',
          path: '/policies',
          router_name: 'policies'
        },
        {
          name: '申请保函',
          path: '',
          router_name: ''
        },
        {
          name: '帮助中心',
          path: '/helps',
          router_name: 'helps'
        },
        {
          name: '下载中心',
          path: '/download',
          router_name: 'download'
        }, {
          name: 'CA办理',
          path: '',
          router_name: ''
        },
      ],
      swiper_list: []
    }
  },
  computed: {},
  watch: {
    $route(n, o) {
      this.active_index = n.name
    }
  },
  mounted() {
    this.get_swiper_list()
  },
  // 组件方法
  methods: {
    // 获取轮播图
    async get_swiper_list() {
      let { rows, code } = await thing_list({ thingSign: '首页广告' });
      if (code == 200 && rows.length) {
        JSON.parse(rows[0].content).forEach(async val => {
          let { data } = await get_img_url(val.pic);
          this.swiper_list.push(data[0].url);
        })
      }
    },
    check(i, k) {
      if (i.path) {
        this.active_index = i.router_name;
        this.$router.push(i.path);
      } else {
        window.open('https://baidu.com')

      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .is-active .el-carousel__button {
  // 指示器激活按钮
  background: #006EFF !important;
}

::v-deep .el-carousel__button {
  // 指示器激活按钮
  height: 5px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.5) !important;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.swiper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 540px;
  }
}

.top {
  font-size: 18px;
  box-sizing: border-box;
  height: 90px;
  background-color: #006EFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .left {
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;

    img {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }

    .logo {
      height: 100%;
      flex-direction: column !important;
      justify-content: space-between;
      align-content: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;

      .logot {
        font-size: 12px !important;
      }
    }
  }

  .right {
    justify-content: flex-start;
    // width: 55%;
    padding: 14px 0px 13px 0;

    .tabs {
      padding: 15px 0;
      margin-right: 40px;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;


      span {
        display: inline-block;
        width: 32px;
        height: 3px;
        // color: #FFFFFF;
        // background-color: #fff;
        margin-top: 5px;
      }
    }

    .btn {
      div {
        width: 68px;
        height: 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(64, 158, 255, 0.2);
        border-radius: 5px 5px 5px 5px;
        line-height: 40px;
        margin-top: 10px;
        color: #006EFF;
        border: 1px solid #006EFF;

      }

      .regit {
        width: 67px;
        height: 40px;
        background: #006EFF;
        border: 1px solid #FFFFFF;
        color: #fff;
        margin-left: 24px;
      }
    }

    .tabs_i {
      padding: 15px 0;
      margin-right: 40px;
      color: #FFFFFF;
      flex-direction: column;
      justify-content: space-between;


      span {
        display: inline-block;
        width: 32px;
        height: 3px;
        color: #FFFFFF;
        background-color: #fff;
        margin-top: 5px;

      }
    }
  }
}
</style>
