<template>
    <div class="page">
        <div class="box ">
            <div class="top flex">
                <div class="left">
                    <img src="../assets/homeimg/logo.png" alt="">
                </div>
                <div class="center">
                    <div class="item1 flex">
                        <span>公司相关</span>
                        <i @click="gopage(item)" v-for="item, index in company" :key="index">{{ item.name }}</i>
                    </div>
                    <div class="item1 flex">
                        <span>帮助中心</span>
                        <i @click="go_help(item.dictValue)" v-for="item in  help_type_arr" :key="item.dictLabel">{{
                            item.dictLabel }}</i>
                    </div>
                    <div class="item1 flex">
                        <span>招之即来</span>
                        <i>地址：{{ address }}</i>
                        <i>电话：{{ phone }}</i>
                        <i>邮箱：{{ mail }}</i>
                    </div>
                </div>
                <div class="right flex">
                    <div class="wx">
                        <img src="../assets/wx.png" alt="">
                        <div>公众号</div>
                    </div>
                    <img src="../assets/code.jpg" alt="">
                </div>
            </div>
            <div class="bottom flex">
                <span><a href="https://beian.miit.gov.cn/" target="_blank" style=" color: rgba(255, 255, 255, 0.5);" >冀ICP备2023014347号-1</a></span>
                <span class="two"><a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=13070902000376"><img src="https://beian.mps.gov.cn/web/assets/logo01.6189a29f.png" style="float: left;height: 25px;"><span style="color: rgba(255, 255, 255, 0.5);">冀公网安备13070902000376号</span></a></span>
                <span>招之即来</span>
            </div>
        </div>
    </div>
</template>

<script>
import { help_type } from '@/api/help'
export default {
    name: 'navBottom',
    components: {},
    props: {
        suspension_arr: {
            default: []
        }
    },
    data() {
        return {
            company: [{
                name: '关于我们',
                url: `/text/abount`
            }, {
                name: '工具下载',
                url: '/download'
            }, {
                name: '法律说明',
                url: '/text/low'
            }],
            phone: '',// 电话
            mail: '',// 邮箱
            address: '',
            help_type_arr: []
        }
    },
    computed: {},
    watch: {
        suspension_arr: {
            handler() {
                this.suspension_arr.forEach(item => {
                    if (item.dictValue == '电话') this.phone = item.dictLabel;
                    if (item.dictValue == '邮箱') this.mail = item.dictLabel;
                    if (item.dictValue == '地址') this.address = item.dictLabel;
                })
            },
            deep: true
        },
    },
    created() {
        // 帮助分类
        this.get_help_type();
    },
    // 组件方法
    methods: {
        go_help(dictValue) {
            this.$router.push({
                path: `/helps/${dictValue}`
            })
        },
        // 帮助分类
        async get_help_type() {
            let { data, code } = await help_type();
            if (code == 200) {
                this.help_type_arr = data;
            }

        }, gopage(item) {
            this.$router.push(item.url)
        }
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}


.page {
    height: 360px;
    background-color: #09428D;
    box-sizing: border-box;
    margin-top: 40px;


    .box {
        width: 1200px;
        margin: auto;
        padding: 40px 0;


        .top {
            align-items: center;

            .left {
                padding-right: 103px;

                img {
                    width: 93px;
                    height: 93px;
                }
            }

            .center {
                flex: 1;
                justify-content: space-between;
                display: flex;


                .item1 {
                    flex-direction: column;
                    font-size: 18px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    cursor: pointer;

                    span {
                        color: rgba(255, 255, 255, 0.5);
                        padding-bottom: 30px;
                    }

                    i {
                        color: #fff;
                        padding-bottom: 20px;
                    }

                    i:last-child {
                        color: #fff;
                        padding-bottom: 40px;
                    }
                }
            }

            .right {
                align-items: center;
                padding-left: 100px;

                >img {
                    width: 75px;
                    height: 73px;
                }

                .wx {
                    margin-right: 40px;

                    >img {
                        width: 39px;
                        height: 31px;
                    }

                    div {
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }
        }

        .bottom {
            color: rgba(255, 255, 255, 0.5);
            font-size: 18px;
            justify-content: center;
            border-top: 2px solid rgba(255, 255, 255, 0.05);
            padding-top: 40px;

            .two {
                padding: 0 136px 0 200px;
            }
        }

    }
}
</style>
