import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const hou = "招之即来";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: hou },
  },
  {
    path: "/text/:type?",
    name: "text",
    meta: { title: hou },
    component: () => import("../views/rich-text.vue"),
  },
  //   交易信息
  {
    path: "/transaction",
    name: "transaction",
    meta: { title: hou },
    component: () => import("../views/TranSaction.vue"),
  },
  //   新闻公告
  {
    path: "/news",
    name: "news",
    meta: { title: hou },
    component: () => import("../views/news.vue"),
  },
  //   公告详情
  {
    path: "/notice_detail/:type?/:id",
    name: "notice_detail",
    meta: { title: hou },
    component: () => import("../views/notice_detail.vue"),
  },
  //   帮助中心
  {
    path: "/helps/:dictValue?",
    name: "helps",
    meta: { title: hou },
    component: () => import("../views/helps.vue"),
  },
  // 下载中心
  {
    path: "/download",
    name: "download",
    meta: { title: hou },
    component: () => import("../views/download.vue"),
  },
  // 政策法规
  {
    path: "/policies",
    name: "policies",
    meta: { title: hou },
    component: () => import("../views/policies.vue"),
  },
  // 政策法规 帮助中心 新闻详情
  {
    path: "/details/:type/:id",
    name: "details",
    meta: { title: hou + "详情" },
    component: () => import("../views/details.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  to.meta.title
    ? (document.title = to.meta.title)
    : (document.title = "招之即来");
  next();
});
export default router;
