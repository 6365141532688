<template>
    <div>
        <div class="top flex">
            <div class="left flex">
                <img src="../assets/homeimg/logo.png" alt="">
                <div class="logo">
                    <div>招之即来</div>
                    <div class="logot" style="font-size: 10px;">Octopus tenders</div>
                </div>

            </div>
            <div class="right flex">
                <div class="tabs flex" :class="activeindex == index ? 'tabs_i' : 'tabs'" v-for="(item, index) in lists"
                    @click="check(item, index)" :key="index">
                    <i>{{ item.name }} </i> <span></span>
                </div>
                <div class="btn flex">
                    <div class="login">登录</div>
                    <div class="regit">注册</div>
                </div>
            </div>

        </div>
        <div class="swiper">
            <el-carousel :interval="5000" height="540px" arrow="always">
                <el-carousel-item v-for="item in swiper_list" :key="item.url">
                    <img :src="item.url" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
export default {
    name: 'navBaruser',
    props: {
        // lists: {
        //     type: Array,
        //     default: []
        // }
    },
    data() {
        return {
            activeName: '6666',
            activeindex: 0,
            lists: [
                {
                    name: '首页',
                    path: '/',
                    value: 0
                },
                {
                    name: '交易信息',
                    path: '/transaction',
                    value: 1
                },
                {
                    name: '开标大厅',
                    path: '/home',
                    value: 2
                },
                {
                    name: '申请保函',
                    path: '/home/home'
                },
                {
                    name: '帮助中心',
                    path: '/home/home'
                },
                {
                    name: '下载中心',
                    path: '/home/home'
                }, {
                    name: 'CA办理',
                    path: '/home/home'
                },
            ],
            swiper_list: [
                {
                    url: require('../assets/homeimg/usera.jpg')
                }, {
                    url: require('../assets/homeimg/userc.jpg')
                }, {
                    url: require('../assets/homeimg/women.jpg')
                }
            ]
        }
    },
    computed: {},
    watch: {},
    created() {

    },
    mounted() { },
    // 组件方法
    methods: {
        handleClick() {

        },
        check(i, k) {
            this.activeindex = k
            this.$router.push(i.path).catch(err => err)
        }
    }
}
</script>
<style lang="scss" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}

.swiper {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 540px;
    }
}

.top {
    font-size: 18px;
    box-sizing: border-box;
    height: 90px;
    background-color: #09428D;


    .left {
        flex: 1;
        width: 400px;
        justify-content: center;
        align-items: center;
        // padding: 33px 0 0 360px;
        color: #fff;

        img {
            width: 48px;
            height: 48px;

            .logo {
                height: 100%;
                flex-direction: column !important;
                justify-content: space-between;
                align-content: center;

                // div:nth-of-type(1){
                //     font-size: 18px;
                //     font-weight: 700;
                // }
                .logot {
                    font-size: 12px !important;
                }
            }
        }

    }

    .right {
        justify-content: flex-start;
        width: 55%;
        padding: 14px 0px 13px 0;

        .tabs {
            padding: 15px 0;
            margin-right: 40px;
            color: rgba(255, 255, 255, .6);
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        .btn {
            div {
                width: 68px;
                height: 40px;
                background: #FFFFFF;
                box-shadow: 0px 4px 10px 0px rgba(64, 158, 255, 0.2);
                border-radius: 5px 5px 5px 5px;
                line-height: 40px;
                margin-top: 10px;
                color: #006EFF;
                border: 1px solid #006EFF;

            }

            .regit {
                width: 67px;
                height: 40px;
                background: #006EFF;
                border: 1px solid #FFFFFF;
                color: #fff;
                margin-left: 24px;
            }
        }

        .tabs_i {
            padding: 15px 0;
            margin-right: 40px;
            color: #FFFFFF;
            flex-direction: column;
            justify-content: space-between;


            span {
                display: inline-block;
                width: 32px;
                height: 3px;
                color: #FFFFFF;
                background-color: #fff;
                margin-top: 5px;

            }
        }
    }
}
</style>
