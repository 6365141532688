import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import "@/assets/icons/iconfont.css";
import axios from "axios";
import * as Utils from "@/utils/utils.js";
// 文件上传组件
import FileUpload from "@/components/FileUpload";
import blueTitleLine from "@/components/blue-title-line.vue";


Vue.prototype.utils = Utils;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.component('FileUpload', FileUpload)
Vue.component("blueTitleLine", blueTitleLine);
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
