/****   request.js   ****/
// 导入axios
import axios from "axios";
import { baseURL } from "./url.js";
import { Message } from "element-ui";

const service = axios.create({
  // 公共接口
  baseURL,
  // 超时时间
  timeout: 5000,
});
service.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      "Content-Type": "application/json", //配置请求头
    };
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log(response.data);
    if (response.data.code == 200) {
      return response.data;
    } else if (response.data.code == 401) {
      Message.error(response.data.msg);
      // router.push("/login");
      return Promise.resolve(response.data);
    } else if (response.data.code == 410) {
      return Promise.resolve(response.data);
    } else {
      // console.log(response.data.message);
      Message.error(response.data.msg);
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    //
    //  异常响应
    if (error && error.response) {
      var code = error.response.status;
      if (code == 401) {
        // 登录
        error.message = "请先登录";
      } else if (code == 404 || code == 500) {
        error.message = "请求失败";
      } else {
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Message.error("服务器响应超时");
      }
      error.message = "连接服务器失败";
    }
    Message.error(error.msg);
    // console.log(error, 'error')
    return Promise.resolve(error.response);
  }
);
//4.导入文件
export default service;
