<template>
  <div class="home">

    <div class="zhi_icon flex">
      <div class="w flex">
        <div class="tabs flex" @click="jump(item.url)" v-for="(item, index) in login_list" :key="index">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <notice @change="get_common_list"/>
    <div class="news" v-if="common_list.length">

      <div class="newlist flex" v-for="item in common_list" :key="item.id" @click="go_notice_detail(item)">
        <div class="l">
          <span>【{{ utils.get_type_name(item.type) }}】</span>
          <span>{{ item.xiaquname ? "【" + item.xiaquname + "】" : "" }}{{ item.name }}</span>
        </div>
        <div class="r" v-if="item.endtime">
          截至时间：{{ item.endtime }}
        </div>
      </div>
    </div>

    <el-empty v-else description="暂无公告"></el-empty>
    <div class="move" @click="go_path('/transaction')">查看更多></div>
    <template v-if="teaching.length">
      <div class="teach">
        <div class="title flex">
          <span>平台使用教学</span>
        </div>
        <div class="card flex">
          <div class="shipin" v-for="item in teaching" :key="item.src" @click="go_page(item.src)">
            <img :src="item.img_src" alt="">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="move" @click="go_path('/helps')">查看更多></div>
    </template>
    <div class="policy">
      <div class="title flex">
        政策法规
      </div>
      <div class="card ">
        <div class="newlist flex" v-for="item in fagui_list" :key="item.faguiId" @click="go_details(item)">
          <div class="l"> {{ item.title }}</div>
          <div class="r">{{ item.createTime }}</div>
        </div>
      </div>
    </div>
    <div class="move" @click="go_path('/policies')">查看更多></div>
  </div>
</template>

<script>
import {
  common_list,
  fagui_list,
  thing_list,
  get_img_url
} from '@/api/home';

import router from '@/router';
import notice from '@/components/Notice.vue'

export default {
  components: {notice},
  props: {},
  data() {
    return {
      login_list: [{
        img: require('../assets/homeimg/img3.png'),
        name: '招标代理登录',
        eng: 'Bidding agent login',
        url: 'https://zbdl.htbidding.com'
      }, {
        img: require('../assets/homeimg/img2.png'),
        name: '投标企业登录',
        eng: 'Bidding agent login',
        url: 'https://tbr.htbidding.com'
      }, {
        img: require('../assets/homeimg/img1.png'),
        name: '招标人登录',
        eng: 'Bidding agent login',
        url: 'https://zbr.htbidding.com'
      }, {
        img: require('../assets/homeimg/img4.png'),
        name: '专家登录',
        eng: 'Bidding agent login',
        url: 'https://zhuanjia.htbidding.com'
      }],
      common_list: [],// 公告列表
      fagui_list: [],// 法规列表
      teaching: [],// 平台使用教学
      query_data: {
        type: 'zbgg',
        pageSize: 10,
        pageNum: 1,
        isAsc: 'descending',
        orderByColumn: 'createTime'
      }
    }
  },
  mounted() {
    this.$store.commit('setnav', true);
    // 公告列表
    this.get_common_list();
    // 法规列表
    this.get_fagui_list();
    // 平台使用教学
    this.get_teaching()
  },
  // 组件方法
  methods: {
    jump(url) {
      window.open(url, '_blank')
    },
    go_notice_detail(item) {
      this.$router.push({
        path: `/notice_detail/${item.type}/${item.id}`
      })
    },
    // 详情
    go_details(item) {
      this.$router.push({
        path: `/details/law/${item.faguiId}`
      })
    },
    // 平台使用教学
    go_page(url) {
      window.open(url)
    },
    // 平台使用教学
    async get_teaching() {
      let {rows, code} = await thing_list({thingSign: '平台使用教学'});
      if (code == 200 && rows.length) {
        JSON.parse(rows[0].content).forEach(async val => {
          let {data} = await get_img_url(val.pic);
          this.teaching.push({
            ...val,
            img_src: data[0].url,
          });
        })
      }
    },

    // 公告列表
    async get_common_list(e) {
      if (e) this.query_data = {...this.query_data, ...e}
      let {code, rows} = await common_list(this.query_data);
      if (code == 200) {
        this.common_list = rows;
      }

    },
    // 法规列表
    async get_fagui_list() {
      let {code, rows} = await fagui_list({
        pageSize: 10
      });
      if (code == 200) {
        this.fagui_list = rows;
      }
    },
    check(index) {
      this.active = index
    },
    go_path(path) {
      router.push({
        path
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.move {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #909399;
  line-height: 26px;
  width: 1200px;
  margin: 40px auto 0;
  cursor: pointer;
}

.flex {
  display: flex;
  box-sizing: border-box;
}

.home {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .zhi_icon {
    padding: 40px 0;
    // display: flex;
    justify-content: space-between;
    background: #F7F8FA;
    height: 200px;

    .tabs {
      width: 285px;
      height: 120px;
      background: #006EFF;
      box-shadow: 0px 0px 14px 0px rgba(15, 59, 149, 0.1);
      border-radius: 15px;
      color: #fff;
      font-size: 20px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: #328AFF;
        box-shadow: 0px 0px 14px 0px rgba(10, 26, 59, 0.4);
      }
    }
  }

  .news {
    box-sizing: border-box;
    width: 1200px;
    margin: 24px auto 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(24, 29, 41, 0.08);
    border-radius: 10px;
    padding: 0 30px;

    .newlist {
      padding: 24px 0;
      cursor: pointer;
      color: #323347;
      font-size: 16px;
      border-bottom: 1px solid #F2F6FC;

      &:hover {
        color: #006EFF;
      }

      &:last-child {
        border: none;
      }

      .l {
        span:nth-of-type(1) {
          margin-right: 10px;
        }
      }
    }

  }

  .teach {
    margin: 80px auto 0;
    box-sizing: border-box;
    width: 1200px;

    .title {
      font-size: 24px;
      font-weight: 700;
      padding-bottom: 40px;
      justify-content: center;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #323347;
    }

    .card {
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(24, 29, 41, 0.08);
      border-radius: 10px 10px 10px 10px;

      .shipin {
        cursor: pointer;
        padding: 40px 40px 25px 40px;
        text-align: center;

        &:hover {
          img {
            box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.5);
          }
        }

        img {
          width: 320px;
          height: 200px;
          margin-bottom: 20px;

        }
      }
    }
  }

  .policy {
    box-sizing: border-box;
    width: 1200px;
    margin: 80px auto 0;

    .card {
      padding: 0 30px !important;
    }

    .newlist {
      justify-content: space-between;
      padding: 24px 0;
      cursor: pointer;
      color: #323347;
      font-size: 16px;
      border-bottom: 1px solid #F2F6FC;

      &:hover {
        color: #006EFF;
      }

      &:hover {
        color: #006EFF;
      }

      .l {
        span:nth-of-type(1) {
          margin-right: 20px;
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px;


    }

    .card {
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(24, 29, 41, 0.08);
      border-radius: 10px 10px 10px 10px;

      padding: 40px;
    }
  }
}


.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
