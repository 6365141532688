import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    topNav: false,
    show_swiper: true,
  },
  getters: {},
  mutations: {
    setnav(state) {
      state.topNav = true;
    },
  },
  actions: {},
  modules: {},
});
