import http from "../utils/http";
// 公告列表
export function common_list(params) {
  return http.get(`/guanwang/common_list`, params);
}
// 法规列表
export function fagui_list(params) {
  return http.get(`/guanwang/fagui_list`, params);
}
// 轮播图 || 平台使用教学 || 关于我们 || 法律说明
export function thing_list(params) {
  return http.get(`/guanwang/thing_list`, params);
}
// 获取图片路径
export function get_img_url(params) {
  return http.get(`/system/oss/listByIds/${params}`);
}
// 下载中心
export function down_list(params) {
  return http.get(`/guanwang/down_list`, params);
}
