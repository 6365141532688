<template>
  <div id="app">
    <navBar></navBar>

    <div class="content">
      <router-view :key="$route.fullPath" />
    </div>
    <navBottom :suspension_arr="suspension_arr"></navBottom>
    <!-- 右侧悬浮窗 -->
    <suspension :suspension_arr="suspension_arr" />

  </div>
</template>
<script>
import navBar from './components/navBar.vue'
import navBottom from './components/navBottom.vue'
import navBaruser from './components/navBaruser.vue';
import suspension from './components/suspension.vue';
import { wangzhan_info } from '@/api/help'
export default {
  components: {
    navBar,
    navBottom,
    navBaruser,
    suspension

  },
  data() {
    return {
      suspension_arr: [
        {
          dictValue: '在线客服',
          icon: 'icon-kefu',
        }
      ],
    }
  },
  mounted() {
    // 配置信息
    this.get_wangzhan_info();

  },
  methods: {
    async get_wangzhan_info() {
      let { data, code } = await wangzhan_info();
      if (code == 200) {
        data.forEach(item => {
          item['icon'] = this.get_icon(item.dictValue)
        });
        this.suspension_arr = [...this.suspension_arr, ...data];
      }
    },
    get_icon(key) {
      let obj = {
        '微信': 'icon-weixin1',
        '邮箱': 'icon-youxiang',
        '电话': 'icon-dianhua',
        'qq': 'icon-qq'
      }
      return obj[key]
    }
  }

}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Microsoft YaHe, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
}

.pointer {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.img_box {
  overflow: hidden;

  img:hover {
    transform: scale(1.1);
    transition: all 0.8s linear;
  }
}

.line-1 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-3 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-4 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

.w {
  width: 1200px;
  margin: 0 auto;
}

div {
  box-sizing: border-box;
}

i {
  font-style: normal;
}
</style>
