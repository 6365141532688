<template>
    <div class="suspension">

        <Transition name="fade">
            <div class="suspension_box" v-show="is_show">
                <template v-for="(item, i) in suspension_arr">
                    <div class="item"
                        v-if="item.dictValue == '电话' || item.dictValue == '邮箱' || item.dictValue == 'qq' || item.dictValue == '在线客服'"
                        :key="i" @mouseenter="show_sub = item.dictValue" @mouseleave="show_sub = ''">
                        <span class="iconfont icon" :class="item.icon"></span>
                        <span>{{ item.dictValue }}</span>

                        <div class="show_sub" v-if="item.dictValue == show_sub && item.dictLabel">
                            <span class="iconfont icon" :class="item.icon"></span>
                            {{ item.dictLabel }}
                        </div>
                    </div>
                </template>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: 'suspension',
    components: {},
    props: {
        suspension_arr: {
            default: []
        }
    },
    data() {
        return {
            show_sub: '',
            is_show: false

        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        window.addEventListener('scroll', () => {
            const scrollTop = document.documentElement.scrollTop;
            if (scrollTop > 470) {
                this.is_show = true
            } else {
                this.is_show = false
            }
        });
        // this.get_wangzhan_info();

    },
    // 组件方法
    methods: {

    }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.suspension_box {
    width: 102px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 10px 10px;
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-30%);
    z-index: 9999;
    // overflow: hidden;

    .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0;
        cursor: pointer;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #909399;
        line-height: 26px;
        position: relative;



        &:hover {
            background: #006EFF;
            color: #fff;
            transition: all .2s;

            .icon {
                color: #fff;

            }
        }

        .icon {
            color: #006EFF;
            font-size: 30px;
            margin-bottom: 5px;
        }


        .show_sub {
            padding: 30px 20px;
            box-sizing: border-box;
            // width: 220px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            position: absolute;
            right: 112px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #303133;
            animation: fadenum .5s ease;
            animation-fill-mode: forwards;
            overflow: hidden;

            .icon {
                color: #006EFF;
                font-size: 30px;
                margin: 0 30px 0 0;
            }

            @keyframes fadenum {
                0% {
                    width: 60px;
                }

                100% {
                    width: 250px;
                }
            }
        }



    }
}
</style>
