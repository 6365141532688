import http from "../utils/http";
// 帮助中心分类
export function help_type(params) {
  return http.get(`/system/dict/data/type/z_help_type`, params);
}
// 帮助列表
export function help_list(params) {
  return http.get(`/guanwang/help_list`, params);
}
// 帮助详情
export function help_info(params) {
  return http.get(`/guanwang/help_info`, params);
}
// 法规分类
export function fagui_type(params) {
  return http.get(`/system/dict/data/type/z_fagui_type`, params);
}
// 法规列表
export function fagui_list(params) {
  return http.get(`/guanwang/fagui_list`, params);
}
// 法规详情
export function fagui_info(params) {
  return http.get(`/guanwang/fagui_info`, params);
}

// 新闻分类
export function new_type(params) {
  return http.get(`/system/dict/data/type/z_new_type`, params);
}
// 新闻列表
export function new_list(params) {
  return http.get(`/guanwang/news_list`, params);
}
// 新闻详情
export function news_info(params) {
  return http.get(`/guanwang/news_info`, params);
}

// 侧边栏邮箱微信
export function wangzhan_info(params) {
  return http.get(`/system/dict/data/type/z_wangzhan_info`, params);
}
