export const area = [
  {
    name: "全省",
    id: 13,
  },
  {
    name: "石家庄市",
    id: 1301,
  },
  {
    name: "唐山市",
    id: 1302,
  },
  {
    name: "秦皇岛市",
    id: 1303,
  },
  {
    name: "邯郸市",
    id: 1304,
  },
  {
    name: "邢台市",
    id: 1305,
  },
  {
    name: "保定市",
    id: 1306,
  },
  {
    name: "张家口市",
    id: 1307,
  },
  {
    name: "承德市",
    id: 1308,
  },
  {
    name: "沧州市",
    id: 1309,
  },
  {
    name: "廊坊市",
    id: 1310,
  },
  {
    name: "衡水市",
    id: 1311,
  },
  // {
  //   name: "雄安新区",
  //   id: 1312,
  // },
];
