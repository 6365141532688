// 公告类型
export function get_type_name(type) {
  let obj = {
    zbgg: "招标公告",
    bggg: "变更公告",
    bydy: "补遗答疑",
    hxrgs: "中标候选人公示",
    zhongbiaogg: "中标公告",
    ycgg: "异常公告",
    jhgg: "招标计划公告",
  };
  return obj[type];
}
// 招标方式名字
export function get_bidding_type_name(type) {
  // 1 公开招标
  // 2 邀请招标
  // 3 竞争性谈判
  // 4 单一来源采购
  // 5 询价
  // 6 竞争性磋商
  // 9 其他
  let obj = {
    1: "公开招标",
    2: "邀请招标",
    3: "竞争性谈判",
    4: "单一来源采购",
    5: "询价",
    6: "竞争性磋商",
    9: "其他",
  };
  return obj[type];
}
